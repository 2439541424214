import {
  Button,
  // Spinner
} from "@chakra-ui/react";
import { useContext, useState, useMemo } from "react";
import { StateContext } from "../../../../Context";

export const BackgroundImageSection = ({ searchProperty, loadingSearchButton }) => {
  const {
    // SEARCH PARAMETERS
    bedrooms,
    setKeyword,
    min,
    setMin,
    max,
    setMax,
    location,
    setLocation,
    type,
    setType,

    // search state
    // setSarchState,
    // searchState,
  } = useContext(StateContext);

  // const [dataSearch, setDateSearch] = useState()
  // // const [filteredData, setFilteredData] = useState([]);
  // // const [wordEntered, setWordEntered] = useState("");
  const [, setSearchLoading] = useState(false);
  // const [removeList, setRemoveList] = useState(false)

  // // CHAKRA UI ERROR TOAST
  // const toast = useToast()
  //  // FETCH LOCATION
  //  useMemo(() =>{
  //     const getLocation = async () => {
  //         try{
  //             setSearchLoading(true)
  //             const response =  await apiFetchClient.get(`v1/locations?keyword=${location}`);

  //             setDateSearch(response?.data.result)
  //             setSearchLoading(false)

  //         }catch(error){

  //             toast({
  //                 title: 'Error',
  //                 description: error?.response.data.message,
  //                 status: 'error',
  //                 duration: 9000,
  //                 isClosable: true,
  //                 position: 'top-left'
  //               })

  //             setSearchLoading(false)
  //         }
  //     }

  //     // getLocation()
  //     const timeOut = setTimeout(() =>{
  //         getLocation()
  //     },1000)
  //     return () =>{
  //         clearTimeout(timeOut)
  //     }

  //  },[setDateSearch,location, toast]);

  const options = [
    { label: "250,000", value: 250000 },
    { label: "500,000", value: 500000 },
    { label: "750,000", value: 750000 },
    { label: "1 million", value: 1000000 },
    { label: "2 million", value: 2000000 },
    { label: "5 million", value: 5000000 },
    { label: "10 million", value: 10000000 },
    { label: "20 million", value: 20000000 },
    { label: "30 million", value: 30000000 },
    { label: "40 million", value: 40000000 },
    { label: "60 million", value: 60000000 },
    { label: "80 million", value: 80000000 },
    { label: "100 million", value: 100000000 },
    { label: "150 million", value: 150000000 },
    { label: "200 million", value: 200000000 },
    { label: "250 million", value: 250000000 },
    { label: "300 million", value: 300000000 },
    { label: "400 million", value: 400000000 },
    { label: "500 million", value: 500000000 },
    { label: "600 million", value: 600000000 },
    { label: "700 million", value: 700000000 },
    { label: "800 million", value: 800000000 },
    { label: "900 million", value: 900000000 },
    { label: "1 billion", value: 1000000000 },
    { label: "2 billion", value: 2000000000 },
    { label: "5 billion", value: 5000000000 },
    { label: "5 billion", value: 5000000000 },
    { label: "10 billion", value: 10000000000 },
    { label: "20 billion", value: 20000000000 },
    { label: "30 billion", value: 30000000000 },
    { label: "40 billion", value: 40000000000 },
    { label: "50 billion", value: 50000000000 },
  ];

  // Location Funtions
  const [suggestions, setSuggestions] = useState([]);
  const [, setListLocationClose] = useState(true);

  useMemo(() => {
    // if (location?.length >= 2 && listLocationClose) {;
    // } else {
    //   setSuggestions([]);
    // }

    async function getSuggestions(query) {
      setSearchLoading(true);
      const response = await fetch(process.env.REACT_APP_URL + `/v1/locations?keyword=${query}`);
      const data = await response.json();
      const dataResult = data?.result
     
      const sortData = dataResult.sort((a,b) => a.location.localeCompare(b.location))
      setSuggestions(sortData);
      setSearchLoading(false);
    }

    const timeOut = setTimeout(() => {
      getSuggestions(location);
    }, 2000);

    return () => clearTimeout(timeOut);
  }, [location, setSuggestions, setSearchLoading]);

  // function handleSuggestionClick(item) {
  //   setListLocationClose(false);
  //   setLocation(item.location);
  //   setSuggestions([]);
  // }

  // const clickLocation =(e) =>{
  //     setLocation(e.target.textContent);
  //     setRemoveList(true);
  // }

  // const onChangeInput = (e) =>{
  //     setLocation(e.target.value);
  //     setRemoveList(false);
  // }

  return (
    <>
      <section className="header_section">
        <div className="search_parameters">
          <div className="content">
            <div className="input_type">
              <div className="location_search_div">
                <select
                  name="location"
                  value={location}
                  onChange={(e) => {
                    if (e.target.value !== location) {
                      setListLocationClose(true);
                    }
                    setLocation(e.target.value);
                  }}
                  className="input location search_values">
                  <option value="">Select Location</option>
                  {suggestions?.map((location,index) => (
                    <option value={location?.location} key={location?.location} >{location?.location}</option>
                  ))}
                </select>
              </div>
              {/* <select name="Type" id="type">
                                <option value="category" >Type</option>
                            </select> */}
              <select type="text" className="select" value={type} placeholder="Type" onChange={(e) => setType(e.target.value)} id="type">
                <option value="">Type</option>
                <option value="residential">Residential </option>
                <option value="" disabled>
                  Commercial
                </option>
              </select>
            </div>
            <div className="details_filter">
              {/* <select name="bedrooms" id="bedrooms">
                                <option value="" >Bedrooms</option>
                            </select> */}
              <select
                name=""
                type="text"
                className="select"
                id="bedrooms"
                value={bedrooms}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Bedrooms">
                <option value="">Bedrooms</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="">6</option>
                <option value="">7</option>
                <option value="">8</option>
                <option value="">9</option>
                <option value="">10</option>
              </select>
              {/* <input
                type="text"
                className="select"
                id="bedrooms"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Bedrooms"
              /> */}
              <select name="min price" className="select" id="min_price" value={min} onChange={(e) => setMin(e.target.value)} placeholder="Min Price">
                <option value="">Min Price</option>
                {options?.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {/* <input type="text" className="select" id="min_price" value={min} onChange={(e) => setMin(e.target.value)} placeholder="Min Price" /> */}
              <select className="select" id="max_price" value={max} onChange={(e) => setMax(e.target.value)} placeholder="Max Price" name="Max Price">
                <option value="">Max Price</option>
                {options?.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {/* <input type="text" className="select" id="max_price" value={max} onChange={(e) => setMax(e.target.value)} placeholder="Max Price" /> */}
            </div>
          </div>
        </div>
        <div className="btn_div">
          <Button isLoading={loadingSearchButton} onClick={searchProperty}>
            Search
          </Button>
        </div>
      </section>
    </>
  );
};
