import "./App.css";
// ROUTER
import { Routes, Route } from "react-router-dom";
// components
import { HomeView } from "./views/homeView";
import { PropertyDetails } from "./components/layouts/Saved";
import { Footer } from "./components/layouts/footer";
import { Header } from "./components/layouts/header";
import { useContext, useEffect, useLayoutEffect } from "react";
import ChatWidget from "./liveChat";
import { ScrollToTop } from "./components/scrollTop";
import { ZoomContainer } from "./components/utils/zoom";
import { StateContext } from "./Context";



function App() {

  const {selectedZoomImage,setSelectedZoomImage} = useContext(StateContext)
  // LOAD TOP THE TOP
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);
  useLayoutEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);


  return (
    <>
     {selectedZoomImage &&(
     <div onClick={() =>setSelectedZoomImage(!selectedZoomImage)} ><ZoomContainer/></div>
     )}
    <div style={{maxWidth:"1800px", margin: "auto", position: "relative"}}>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<HomeView />} />
          <Route path="/property-details/:id" element={<PropertyDetails />} />
        </Route>
      </Routes>
      <Footer />
      <ChatWidget />
    </div>
    </>
  );
}

export default App;
