// import propertyMain from "../../../assets/image2.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useContext, useEffect, useState } from "react";
// assets
import room from "../../../assets/bedrooms.png";
import detail from "../../../assets/detail.png";
import toilet from "../../../assets/toilet.png";
import water from "../../../assets/treatedwater.png";
import security from "../../../assets/security.png";
import parkingspace from "../../../assets/parkingspace.png";
import pets from "../../../assets/petsallowed.png";
import kitchen from "../../../assets/kitchen.png";

// API PROVIDER
import apiFetchClient from "../../../api/apiProvider";

// context
import { StateContext } from "../../../Context";
// router
import { useParams } from "react-router-dom";
import { BeatLoader, ScaleLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
// chakra
import { Alert, AlertIcon, AlertTitle, Button } from "@chakra-ui/react";
import { clipLoaderStyle } from "../main";
import { ZoomContent } from "../../utils/zoom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export function PropertyDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  // RENT BUTTON STATUS
  const [rent, setRent] = useState(false);

  // CONTEXT
  const { productListing ,selectedZoomImage, setSelectedZoomImage} = useContext(StateContext);
  // USEPARAMS()
  const { id } = useParams();

  productListing.filter((results) => results.id === id);

  useEffect(() => {
    async function getProppertyDetails() {
      try {
        const response = await apiFetchClient.get(`/v1/explorer/property/${id}`);
        setData(response.data.result);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    }
    // getProppertyDetails();
    //
    const timeOut = setTimeout(() => {
      getProppertyDetails();
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };

  }, [setData, setIsLoading, setError, id, data]);

  // ADDING BOTH INTERIOR AND EXTERIOR IMAGES
  const slide = [].concat(data.interior_images, data.exterior_images);

  // CHECK FOR LOGIN USER FOR RENTING OPTION
  
  function rentButton() {
    if (!localStorage.getItem("encodedtoken")) {
      setRent(true);
    }
    if (localStorage.getItem("encodedtoken")) {
      window.location.replace(`https://account.myrentease.co/property/${id}`);
    }
  }

const zoomImage = (index) =>{
  setSelectedZoomImage(index)
}

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <BeatLoader color="gray" />
        </div>
      )}
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{error?.message}</AlertTitle>
        </Alert>
      )}
      {data && (
        <div className="property_container">
           {/* <ZoomContainer/> */}
          {selectedZoomImage &&(
          <ZoomContent 
          selectedZoomImage = {selectedZoomImage} 
          isLoading ={isLoading} 
          clipLoaderStyle = {clipLoaderStyle} 
          rent ={rent} rentButton = {rentButton} 
          data  = {data}/>)}
          <div className="property_details_content">
            <div className="carousel-container">
              <Carousel itemClass="caa" responsive={responsive} partialVisible={false}>
                {slide.map((all, index) => (
                  <div
                  onClick={ ()=> zoomImage(all)}
                    // src={all}
                    // alt="icon"
                    style={{
                      backgroundImage: `url(${all})`,
                      backgroundSize: "cover",
                    }}
                    key={index}
                    className="saved-carousel-image"></div>
                ))}
              </Carousel>
            </div>
            <div className="info_container">
              <main className="info">
                {isLoading && (
                  <div style={clipLoaderStyle}>
                    <ScaleLoader color="#287BDC" />
                  </div>
                )}
                <div className="text">
                  <div className="name_top_t">
                    <h2>{data.name}</h2>
                    <img src={detail} alt="detail icon" className="detail_icon" />
                  </div>
                  <p>{data?.description}</p>
                  <h4>
                    Location:{" "}
                    <span>
                      {/* {data?.location_address2} */}
                      {" "}
                      {data?.location_area}
                      {" "}
                      {/* {data?.location_state} */}
                    </span>
                  </h4>
                  <p>
                    Facilities : <span>{data?.facilities && data?.facilities}</span>
                  </p>
                </div>
                <div className="horizontal_line"></div>
                <div className="details">
                  <div className="items">
                    <img src={toilet} alt="toilet" className="toilet" />
                    <span> Toilet</span>
                  </div>
                  <div className="items">
                    <img src={room} alt="bathroom" />
                    <span>{data?.bathrooms} Bathroom</span>
                  </div>
                  <div className="items">
                    <img src={room} alt="bedroom" />
                    <span>{data?.bedrooms} Bedrooms</span>
                  </div>
                </div>
                <div className="horizontal_line"></div>
                <div className="house_details">
                  <div className="details2">
                    <div className="item">
                      <img src={water} alt="treater water" />
                      <span>Treated Water</span>
                    </div>
                    <div className="item">
                      <img src={kitchen} alt="kitchen" />
                      <span> Dedicated Kitchen</span>
                    </div>
                    <div className="item">
                      <img src={parkingspace} alt="parking space" />
                      <span>Parking Space</span>
                    </div>
                    <div className="item">
                      <img src={security} alt="security" />
                      <span>Security</span>
                    </div>
                  </div>
                  <div className="details3">
                    <div className="item">
                      <img src={pets} alt="pets allowed" />
                      <span>Pets Allowed</span>
                    </div>
                  </div>
                </div>
              </main>
              {/* RENT BUTTON OPTION FOR USER AND NON_USER START */}
              {isLoading ? (
                  <div style={clipLoaderStyle}>
                    <ClipLoader color="#287BDC" />
                  </div>
                ):(
              <div className="rent_option">
                <div className="pay" style={{ textAlign: "center" }}>
                  You Pay
                </div>
               
                <span>
                  NGN: {Number(data?.price).toLocaleString("en")}
                  <em style={{ color: "#000" }}>/year</em>
                </span>
                <p>{data?.description}</p>
                {data?.status === "open" ? (
                  <span>Available</span>
                ) : data?.status === "close" ? (
                  <span style={{ color: "gray" }}>Unavailable</span>
                ) : null}
                {!rent && (
                  <div onClick={rentButton} className="btn_cnt">
                    <Button>Rent</Button>
                  </div>
                )}
                {rent && (
                  <div className="login_create">
                    <Button onClick={() => window.location.replace("https://account.myrentease.co/")} className="lgn_btn" variant="solid">
                      Login
                    </Button>
                    <Button onClick={() => window.location.replace("https://account.myrentease.co/")} className="crt_btn" variant="outline">
                      Create Account
                    </Button>
                  </div>
                )}
              </div>)}
              {/* RENT BUTTON OPTION FOR USER AND NON_USER  ENDS */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

