import { ClipLoader } from "react-spinners";
import price from "../../../assets/price.png";
import room from "../../../assets/rooms.png";
import toilet from "../../../assets/toilet.png";
import kitchen from "../../../assets/kitchen.png";
import { ImageSlider } from "./slider";
import { useContext, useEffect, useMemo } from "react";
import { StateContext } from "../../../Context";
import { Pagination } from "../../pagination";
import apiFetchClient from "../../../api/apiProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import emptyPropertyImage from "../../../assets/empty_property.gif";
import { BackgroundImageSection } from "../header/backgroundImage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useToast } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { ScrollToTop } from "../../scrollTop";

export const Main = () => {
  const navigate = useNavigate();

  const toast = useToast();

  useEffect(() => {
    AOS.init({
      once: true,
    });

    AOS.refresh(); // Refresh AOS after initialization
  }, []);
  // page items
  const [pageItems, setPageItems] = useState("");
  const [loading, setLoading] = useState(true);

  const [loadingSearchButton, setLoadingSearchButton] = useState(false);

  // context
  const {
    // itemsPerPage,
    setProductListing,

    // search parameters
    bedrooms,
    min,
    max,
    location,
    type,
  } = useContext(StateContext);

  // pagination
  // User is currently on this page
  const [limit, setLimit] = useState(40);
  const [page, setPage] = useState(1);
  const [nPage, setNPage] = useState();

  const [error] = useState();

  // let DEFAULT_URL = `/v1/explorer/all?page=${page}&limit=${limit}`;
  // const [URL, setURL] = useState(DEFAULT_URL);

  let inputValues = {
    min,
    max,
    bedrooms,
    location,
    type,
  };

  async function fetchExplorerItems(page, limit, searchQuery = []) {
    try {
      setLoading(true);
      setLoadingSearchButton(true);

      let URL = `/v1/explorer/all?page=${page}&limit=${limit}`;

      if (searchQuery?.length > 0) {
        URL += `&${searchQuery.join("&")}`;
      }

      const tokenResponse = await apiFetchClient.get(URL);

      setPageItems(tokenResponse.data.result.results);
      setProductListing(tokenResponse.data.result.results);
      setLoading(false);

      setLimit(tokenResponse.data.result?.perPageItems);
      setPage(tokenResponse.data.result?.currentPage);
      setNPage(tokenResponse.data.result?.page_total);

      setLoadingSearchButton(false);
    } catch (error) {
      setLoadingSearchButton(false);
      setLoading(false);
      setLoading(false);
      setLoadingSearchButton(false);
      // setError(error);
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      // setError(error);
    }
  }

  const searchProperty = (page, limit) => () => {
    const searchQuery = Object.keys(inputValues).map(
      (x) => `${x}=${inputValues[x]}`
    );

    // if (searchQuery?.length > 0) {
    //   `/v1/explorer/all?page=${page}&limit=${limit}`+=`&${searchQuery.join("&")}`;
    //   setURL(`/v1/explorer/all?page=${page}&limit=${limit}`);
    // }
    fetchExplorerItems(page, limit, searchQuery);
  };

  useMemo(() => {
    // API

    fetchExplorerItems(page, limit);
    // fetchItems();

    // RECURSIVE TIMEOUT
    // const timeOut = setTimeout(() => {
    // fetchExplorerItems(page, limit);
    // fetchItems();
    // }, 2000);

    // return () => {
    //   clearTimeout(timeOut);
    // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  const propertyStyle = {
    // display: "flex",
    justifyContent: "center",
  };

  const noPageItems = pageItems.length === 0;

  return (
    <>
      <BackgroundImageSection
        searchProperty={searchProperty(page, limit)}
        loadingSearchButton={loadingSearchButton}
      />
      <main>
        <div className="content">
          {/* {pageItems.length === 0 && (
              <div style={propertyStyle} className="no_property">
                <div className="gif">
                  <img
                    style={emptyPropertyStyle}
                    src={emptyPropertyImage}
                    alt="empty property"
                  />
                  <h3 style={emptyPropertyText}>Sorry!</h3>
                  <p style={noPropertyGrayStyle}>
                    NO property have beem uploaded yet
                  </p>
                </div>
              </div>
            )} */}
          {error && (
            <div style={errorStyles} className="no_property">
              <div className="gif">
                <img
                  style={emptyPropertyStyle}
                  src={emptyPropertyImage}
                  alt="empty property"
                />
                <h3 style={{ textAlign: "center" }}>Sorry!</h3>
                <p style={noPropertyGrayStyle}>{error?.message}</p>
              </div>
            </div>
          )}
          {loading ? (
            <div style={clipLoaderStyle}>
              <ClipLoader color="#287BDC" />
            </div>
          ) : noPageItems ? (
            <div style={propertyStyle} className="no_property">
              <div className="gif">
                <img
                  style={emptyPropertyStyle}
                  src={emptyPropertyImage}
                  alt="empty property"
                />
                <h3 style={emptyPropertyText}> Cant find what your looking for ?</h3>
                <p style={noPropertyGrayStyle}>
                  Upload your preferred property and we would help you finance the
                  rent
                </p>
                <a
                  href={
                    localStorage.getItem("encodedtoken")
                      ? "https://account.myrentease.co/directrent/newrent"
                      : "https://account.myrentease.co"
                  }
                  className="btn">
                  <Button variant="solid">Upload your property</Button>
                </a>
              </div>
            </div>
          ) : (
            <div className="slider_div">
              {pageItems?.map((properties, index) => {
                let interior = properties?.interior_images;
                let exterior = properties?.interior_images;
                const slide = [...interior, ...exterior];
                // image link
                const detailsPageLink = `/property-details/${properties?.id}`;
                return (
                  // <Link

                  //   style={{color: "#000",}}
                  //   key={properties?.id}
                  //   to={`/property_details/${properties?.id}`}
                  //   >
                  <section
                    key={properties?.id}
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay={40 + index * 100}>
                    <ScrollToTop />
                    <div className="slider">
                      <div className="img">
                        <ImageSlider
                          slides={slide}
                          detailsPageLink={detailsPageLink}
                        />
                      </div>
                      <div className="info">
                        <p>{properties?.name}</p>
                        <h2>{properties?.location}</h2>
                        <div className="price">
                          <img src={price} alt="" />
                          <span>
                            NGN{Number(properties?.price)?.toLocaleString("en")} per
                            annum
                          </span>
                        </div>
                        <div className="detailss">
                          <ul>
                            <img src={room} alt="" />
                            <li>Rooms</li>
                          </ul>
                          <ul>
                            <img src={toilet} alt="" />
                            <li>Bathrooms</li>
                          </ul>
                          <ul>
                            <img src={kitchen} alt="" />
                            <li>Kitchen</li>
                          </ul>
                        </div>
                      </div>
                      <Button
                        onClick={() =>
                          navigate(`/property-details/${properties?.id}`)
                        }
                        style={detailsButtonStyles}>
                        Details
                      </Button>
                    </div>
                  </section>
                  // </Link>
                );
              })}
            </div>
          )}
        </div>

        {pageItems.length !== 0 && (
          <Pagination
            recordsPerPage={limit}
            nPages={nPage}
            currentPage={page}
            setCurrentPage={setPage}
            pageItems={pageItems}
          />
        )}
      </main>
    </>
  );
};

const emptyPropertyStyle = {
  height: "60%",
  margin: "auto",
  padding: "auto",
};

const emptyPropertyText = {
  textAlign: "center",
};

const errorStyles = {
  // display: "flex",
  justifyContent: "center",
};

const noPropertyGrayStyle = {
  color: "rgb(187, 184, 184)",
  textAlign: "center",
};

export const clipLoaderStyle = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
};

const detailsButtonStyles = {
  border: "none",
  outline: "none",
  color: "#FFFFFF",
  backgroundColor: "#287BDC",
  width: "100%",
  maxHeight: "30px",
  marginTop: "10px",
};
