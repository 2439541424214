import { Button } from "@chakra-ui/react";
import { ClipLoader } from "react-spinners";
import { Xbar } from "../svg";

export const ZoomContainer = () => {
  return <div className="zoom">
    <Xbar className = "x_mark"/>
  </div>;
};

export const ZoomContent = ({isLoading, clipLoaderStyle, rent, rentButton,data,selectedZoomImage}) => {
  return (
    <>
      <div className="zoom_content_img" >
        <div className="img_main_zoom">
          <div className="zoom_">
            <div className="zoom_image">
              <img src={selectedZoomImage} alt="" />
            </div>
            <div className="rent_option">
              <div className="pay" style={{ textAlign: "center" }}>
                You Pay
              </div>
              {isLoading && (
                <div style={clipLoaderStyle}>
                  <ClipLoader color="#287BDC" />
                </div>
              )}
              <span>
                NGN: {Number(data?.price).toLocaleString("en")}
                <em style={{ color: "#000" }}>/year</em>
              </span>
              <p>{data?.description}</p>
              {data?.status === "open" ? (
                <span>Available</span>
              ) : data?.status === "close" ? (
                <span style={{ color: "gray" }}>Unavailable</span>
              ) : null}
              {!rent && (
                <div onClick={rentButton} className="btn_cnt">
                  <Button>Rent</Button>
                </div>
              )}
              {rent && (
                <div className="login_create">
                  <Button onClick={() => window.location.replace("https://account.myrentease.co/")} className="lgn_btn" variant="solid">
                    Login
                  </Button>
                  <Button onClick={() => window.location.replace("https://account.myrentease.co/")} className="crt_btn" variant="outline">
                    Create Account
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
