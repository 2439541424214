import { Bars, RentEaseLogo, Xbar } from "../../utils/svg"
import { motion } from "framer-motion";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

import {
    // Popover,
    // PopoverTrigger,
    // PopoverContent,
    // PopoverHeader,
    // PopoverArrow,
    // PopoverCloseButton,
    Avatar,
} from '@chakra-ui/react'
//   api
import apiFetchClientUser from "../../../api/userProvider"
import { useState, useEffect } from "react";

// ASSETS
import background from "../../../assets/background.png";


export const USER_URL = "/user/profile"
export const Header = () => {

    const [email, setEmail] = useState();
    const [, setError] = useState();
    const [loading, isLoading] = useState(false);

    const [showNav, setShowNav] = useState(false)

    useEffect(() => {
        async function getProfile() {
            try {
                isLoading(true)
                const response = await apiFetchClientUser.get(USER_URL)
                setEmail(response.data.result.email)
                isLoading(false)
            } catch (error) {
                setError(error);
                isLoading(false)
            }
        }
        // RECURSIVE TIMEOUT 
        const timeOut = setTimeout(() => {
            if (email === null || email === undefined || email.length === 0) {
                getProfile()
            }
            else {
                return null
            }
        }, 1000);

        return () => {
            clearTimeout(timeOut);
        }

    }, [setError, email]);

    // TOGGLE NAVIGATION LIST 
    function toggleNav() {
        setShowNav(!showNav)
    }


    return (
        <>
            <header className="top_header">
                <div className="top_content">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2 }}
                        className="img_logo"
                    >
                        <RentEaseLogo className="img_logo_c" />
                    </motion.div>
                    {/* nav list  */}
                    <ul className="explorer_navigation_header_list">
                        <a
                            href="https://myrentease.co/"
                            // target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            <li>Home</li>
                        </a>
                        <a href="https://agent.myrentease.co/"
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            <li>Become an Agent</li>
                        </a>
                        <NavLink to="/" className="link"><li>Properties</li></NavLink>
                        <a
                            href="https://www.myrentease.co/faq"
                            className="link"
                        >
                            <li>FAQ</li>
                        </a>
                    </ul>

                    {localStorage.getItem("encodedtoken") && (
                        <div
                            className="pop" style={{ zIndex: "10", cursor: "pointer" }}
                            onClick={() => window.location.replace("https://account.myrentease.co")}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 4.5 }}
                                className="profile">
                                {email && (
                                    <h4>
                                        {email}
                                    </h4>)}
                                {loading && (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    >
                                        <BeatLoader color="gray" />
                                    </div>)}
                                {localStorage.getItem("encodedtoken") && (
                                    <div className="avat" ><Avatar name={email} /></div>
                                )}
                                {/*  */}
                            </motion.div>
                        </div>)}
                    {!localStorage.getItem("encodedtoken") && (
                        <div className="btn_b">
                            <Link onClick={() => window.location.replace("https://account.myrentease.co")} target="_blank" >
                                <Button
                                    variant='outline'
                                    colorScheme="287BDC"
                                    className="signin_btn"
                                >
                                    Apply
                                </Button>
                            </Link>
                            <Link onClick={() => window.location.replace("https://account.myrentease.co/")} target="_blank">
                                <Button
                                    variant="solid"
                                    className="login_btn"
                                >
                                    Login
                                </Button>
                            </Link>
                        </div>
                    )}
                    <div className="bars">
                        {!showNav && (<Bars onClick={toggleNav} />)}
                        {showNav && (<Xbar onClick={toggleNav} />)}
                    </div>
                </div>
                {/* MOBILE NAV START */}
                <div className={showNav ? "mobile_main_click" : "mobile_main"}>
                    <ul
                        className="mobile"
                    >
                        {/* {localStorage.getItem("encodedtoken")&&(
                        <div className="popup_mobile">
                            <Popover style={{outline: "none"}} className="popOver" placement='auto'>
                                <PopoverTrigger>
                                    <Avatar name={email}/>
                                </PopoverTrigger>
                                <PopoverContent  style={popupStyles} className="popUp" >
                                    <PopoverHeader style={imgpop} >
                                        <Avatar/>
                                    </PopoverHeader>
                                    <PopoverArrow  />
                                    <PopoverCloseButton  />
                                    {loading ?(<div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "auto"
                                    }}
                                    >
                                        <ClipLoader color="#287BDC" />
                                    </div>):
                                    <p style={{fontSize: "1.1rem"}} align="center">{email}</p>}
                                    <a 
                                        href = "https://account.myrentease.co/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button 
                                            style={button}  
                                            variant="solid" 
                                        >
                                            Dashboard
                                        </Button>
                                    </a>
                                    <ul align="left">
                                    <div className="horizontal_line"></div>
                                        <Link className="l"><li>Settings</li></Link>
                                        <Link className="l"><li>Help</li></Link>
                                        <div className="horizontal_line line"></div>
                                        {window.localStorage.getItem("encodedtoken")&&(
                                            <Link className="l" onClick={() => window.localStorage.removeItem("encodedtoken")} >
                                                <li>Sign Out</li>
                                            </Link>)
                                        }
                                    </ul>
                            </PopoverContent>
                            </Popover>
                        </div>)} */}
                        <div
                            variants={sideVariants}
                            initial={{ width: 0 }}
                            animate={{ width: "100%" }}
                            exit={{ width: 0, transition: { delay: 0.3, duration: 0.65 } }}
                            className="lin"
                        >
                            <div className="mobile_nav_line"></div>
                        </div>
                        <div className="list">
                            <a
                                href="https://myrentease.co/"
                                onClick={() => setShowNav(false)}
                                // target="_blank"
                                className="link"
                                rel="noreferrer"
                            >
                                <li>Home</li>
                            </a>
                            <a href="https://agent.myrentease.co/"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                                onClick={() => setShowNav(false)}
                            >
                                <li>Become an Agent</li>
                            </a>
                            <NavLink to="/" className="link" onClick={() => setShowNav(false)}><li>Properties</li></NavLink>
                            <a
                                href="https://www.myrentease.co/faq"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                                onClick={() => setShowNav(false)}
                            >
                                <li>FAQ</li>
                            </a>
                            {/* <a
                                href="https://www.myrentease.co/faq"
                                target="_blank" 
                                rel="noreferrer"
                                className="link"
                            >
                                <li>FAQ</li>
                            </a> */}
                            <a
                                onClick={() => setShowNav(false)}
                                rel="noreferrer"
                                href="https://account.myrentease.co" className="link"
                            >
                                Dashboard
                            </a>
                        </div>
                        {/* new changes added (create and login btn to the dropdown nav) */}
                        {!localStorage.getItem("encodedtoken") && (
                            <div className="btn_b_mobile">
                                <a
                                    rel="noreferrer"
                                    href="https://account.myrentease.co"
                                    onClick={() => setShowNav(false)}
                                    target="_blank" >
                                    <Button
                                        variant='outline'
                                        colorScheme="287BDC"
                                        className="signin_btn"
                                    >
                                        Apply
                                    </Button>
                                </a>
                                <a
                                    rel="noreferrer"
                                    href="https://account.myrentease.co/login"
                                    onClick={() => setShowNav(false)}
                                    target="_blank">
                                    <Button
                                        variant="solid"
                                        className="login_btn"
                                    >
                                        Login
                                    </Button>
                                </a>
                            </div>
                        )}
                    </ul>
                </div>
                {/* MOBILE NAV END */}
            </header>
            <Outlet />
        </>
    )
}


// FRAMER MOTION
const sideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.2,
            staggerDirection: -1
        }
    },
    open: {
        transition: {
            staggerChildren: 0.2,
            staggerDirection: 1
        }
    }
};


//   styles 
export const popupStyles = {
    // height: "60%",
    padding: "1.5em",
    outline: "none",
}
export const button = {
    width: "85%",
    border: "none",
    outline: "none",
    borderRadius: "5px",
    margin: "1em auto auto",
    color: "#287BDC",
    padding: "1.5em",
    fontSize: "1.2rem",
}
export const close = {
    border: "none",
    outline: "none",
}
export const imgpop = {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
}

export const backgroundImageStyle = {
    background: `url(${background})`,
    backgroundSize: "cover",
}
