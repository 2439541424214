import axios from "axios";
export const RENT_EASE_URL = process.env.REACT_APP_USER_URL


// GET URL REF TO ENCODE THE TOKEN FROM USER END
const location = window.location.search;
const queryParams = new URLSearchParams(location);
const getRef = queryParams.get("ref");
const decodeToken = atob(getRef); // decode encoded token from user dashboard

// SAVE ENCODED TOKEN IN STORAGE 
 window.localStorage.setItem("encodedtoken", decodeToken);
export  const access_token = localStorage.getItem("encodedtoken");
function apiFetchClientUser() {
        const option ={
            baseURL: RENT_EASE_URL,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${access_token}`
            },
        };

        const axiosInstance = axios.create(option);

        axiosInstance.interceptors.request.use(function (config) {
            // const token = localStorage.getItem("token");
            // config.headers.Authorization = token ? `Bearer ${token}` : "";
            return config 
        })


        axiosInstance.interceptors.response.use(
            response => response,
            error => {
                if(error.response.status === 403 || error.response.status === 401){
                    localStorage.clear()
                // window.location.href = '/login';
                // localStorage.removeItem("token");
                // localStorage.removeItem("tk");
                // alert("expired token please login again")
                }
                // if(error === 409){
                //     window.location.href = "/login"
                // }

                return Promise.reject(error);

            }
        )
        return axiosInstance
}
export default apiFetchClientUser()

