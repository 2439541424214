import { createContext, useState } from "react";




export const StateContext = createContext();

export const ContextProvider = (props) => {

    //    pagination 
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [productListing, setProductListing] = useState([])

    // search parameters
    const [bedrooms, setKeyword] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const [location, setLocation] = useState("");
    const [type, setType] = useState("");


    // SEARCH STATE
    const [searchState, setSarchState] = useState(false);

    const [selectedZoomImage, setSelectedZoomImage] = useState()


    return (
        <StateContext.Provider value={{
            // pagination logic States
            currentPage,
            setCurrentPage,
            itemsPerPage,
            productListing,
            setProductListing,
            selectedZoomImage,
             setSelectedZoomImage,

            // search parmeter 
            bedrooms,
            setKeyword,
            min,
            setMin,
            max,
            setMax,
            location,
            setLocation,
            type,
            setType,

            // search state
            searchState,
            setSarchState
        }} >
            {props.children}
        </StateContext.Provider>
    )
}