import axios from "axios";


export  const access_token = localStorage.getItem("token");

export const BASE_URL = process.env.REACT_APP_URL
export const RENT_EASE_URL = process.env.REACT_APP_USER_URL

function apiFetchClient() {
        const option ={
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": `Bearer ${access_token}`
            },
        };

        const axiosInstance = axios.create(option)

        axiosInstance.interceptors.request.use(function (config) {
            // const token = localStorage.getItem("token");
            // config.headers.Authorization = token ? `Bearer ${token}` : "";
            return config 
        })


        axiosInstance.interceptors.response.use(
            response => response,
            error => {
                // if(error.response.status === 403 || error.response.status === 401){
                // window.location.href = '/login';
                // localStorage.removeItem("token");
                // localStorage.removeItem("tk");
                // alert("expired token please login again")
                // }
                // if(error === 409){
                //     window.location.href = "/login"
                // }

                return Promise.reject(error);

            }
        )
        return axiosInstance
}
export default apiFetchClient()

