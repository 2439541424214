import React, { useState } from "react";
import arrowleft from "../../../../assets/arrowLeft.png";
import arrowright from "../../../../assets/arrowRight.png";
import heart from "../../../../assets/heart.png";
import { Link } from "react-router-dom";

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "fixed",
};

export const ImageSlider = ({ slides, detailsPageLink }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex]})`,
  };

  return (
    <div className="slideMain">
      <div className="heart">
        <img src={heart} alt="" className="h_img" />
      </div>
      <div className="arrow_container">
        <div onClick={goToPrevious} className="leftArrowStyles">
          <img src={arrowleft} alt="" className="a" />
        </div>
        <div onClick={goToNext} className="rightArrowStyles">
          <img src={arrowright} alt="" />
        </div>
      </div>
      <Link to={detailsPageLink}>
        <div onClick={detailsPageLink} style={slideStylesWidthBackground}></div>
      </Link>
      <div className="dotsContainerStyles">
        {slides.map((_, index) => (
          <div
            className={`dotStyle ${index === currentIndex ? "active_class" : ""}`}
            key={index}
            onClick={() => goToSlide(index)}></div>
        ))}
      </div>
    </div>
  );
};
