// assets
// import { Link } from "react-router-dom";
import {
  Facebook,
  // Google,
  Instagram,
  // Ios,
  RentEaseLogo,
  Twitter,
  Youtube,
} from "../../utils/svg";

export const Footer = () => {
  return (
    <>
      <footer>
        {/* <div className="subscribe_letter">
                    <h6>Subscribe Newsletter</h6>
                    <div className="email">
                        <div className="input">
                            <div>
                                <Mail />
                            </div>
                            <input type="email" src=""  placeholder="Enter your Email"/>
                        </div>
                        <button>Subscribe</button>
                    </div>
                </div> */}
        <section>
          <div className="rentease">
            <RentEaseLogo
              width={180}
              // height={150}
              className="rent"
            />
            <p>
              Rentease offers a reliable solution for stress-free and affordable housing options of your preference, We offer Flexible installment
              payments and diverse selections of properties from locations accross Nigeria
            </p>
          </div>
          <div className="comp">
            <ul className="company">
              <li className="first">Company</li>
              <a href="https://www.myrentease.co/">
                <li>About Us</li>
              </a>
              {/* <li>Blog</li> */}
              {/* <li>Careers</li> */}
              <li>Contact Us</li>
            </ul>
            <ul className="company">
              <li className="first">Support</li>
              <a href="https://rentease.tawk.help/">
                <li>Help Center</li>
              </a>
              {/* <li>Safety Center</li> */}
              {/* <li>Community Guidlines</li> */}
            </ul>
            <ul className="company">
              <li className="first">Legal</li>
              <a href="https://www.myrentease.co/Cookie">
                <li>Cookies Policy</li>
              </a>
              <a href="https://www.myrentease.co/Privacy">
                <li>Private Policy</li>
              </a>
              <a href="https://www.myrentease.co/Termsofservice">
                <li>Terms of Service</li>
              </a>
            </ul>
          </div>
          {/* <div className="app">
                        <h4>Install app</h4>
                        <div className="social_img">
                            <Google className="gg" />
                            <Ios className="gg" />
                        </div>
                        <div className="social_icons">
                            <div className="icons fb"><Facebook/></div>
                            <div className="icons tw"><Twitter/></div>
                            <div className="icons yt"><Youtube/></div>
                            <div className="icons inst"><Instagram/></div>
                        </div>
                    </div> */}
          <div className="app">
            <h4>Socials</h4>
            <div className="social_icons">
              <div className="icons fb">
                <Facebook />
              </div>
              <div className="icons tw">
                <Twitter />
              </div>
              <div className="icons yt">
                <Youtube />
              </div>
              <div className="icons inst">
                <Instagram />
              </div>
            </div>
          </div>
        </section>
        <div className="line"></div>
        <p className="rights">@2023 All rights reserved</p>
      </footer>
    </>
  );
};
