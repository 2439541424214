
// import { BackgroundImageSection } from "../../components/layouts/header/backgroundImage"
import { Main } from "../../components/layouts/main"

export const HomeView = () =>{
    return(
        <>
            {/* <BackgroundImageSection/> */}
            <Main/>
        </>
    )
}