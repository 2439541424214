export function Pagination({ nPages, currentPage, setCurrentPage, recordsPerPage, pageItems }) {
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const getPageNumbers = () => {
    const allPageNumbers = [...Array(nPages + 1).keys()].slice(1);

    // Show only three page numbers at a time
    if (currentPage <= 3) {
      return allPageNumbers.slice(0, 4);
    } else if (currentPage >= nPages - 1) {
      return allPageNumbers.slice(-3);
    } else {
      return allPageNumbers.slice(currentPage - 2, currentPage + 1);
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination-container">
      <ul className="pagination">
        {currentPage > 1 && (
          <li style={{ cursor: "pointer" }} onClick={prevPage} className="page-number">
            {"<"}
          </li>
        )}
        {pageNumbers.map((number, index) => (
          <li
            key={index}
            onClick={() => setCurrentPage(number)}
            className={`page-number ${number === currentPage ? "active" : "page_main"}`}
          >
            {number}
          </li>
        ))}
        {currentPage < nPages && (
          <li onClick={nextPage} className="page-number">
            {">"}
          </li>
        )}
      </ul>
    </div>
  );
}
